@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html {
  box-sizing:border-box;
  font-size:16px;
  font-family:Futura PT, sans-serif;
  font-weight:400;
  font-style:normal;
}

*, *:before, *:after {
  box-sizing:inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin:0;
  padding:0;
  font-weight:normal;
}

h1, h2, h3, h4, h5, h6 {
  font-family:oswald, sans-serif;
  font-weight:400;
  font-style:normal;
}

h1, h2, h3, h4, h5, h6, p {
  padding:0.333em 0;
}

ol, ul {
  list-style:none;
}

img {
  max-width:100%;
  height:auto;
  max-height:50em;
}

/* Some breakpoints at font size 16px:

80em = 1200px
50em = 800px
30em = 480px

 */

.container {
  width:100%;
}

header .mat-form-field-flex {
  background:rgba(255,255,255,0.85);
  padding:0 .666em 0.666em;
}
header .mat-form-field-suffix {
  margin-top:0.333em;
  position:relative;
  top:0.333em;

}
header .mat-form-field-flex mat-icon {
  color:#333
}

markdown-table {
  text-align:left;
}

markdown table tr:hover {
  background:rgba(150,150,150,0.1);
}

markdown table {
  margin:1em 0;
}

.mat-expansion-indicator::after {
  color:white;
}

markdown ul li {
  list-style:disc;
  margin-left:1rem;
  line-height:1.3;
}

@media only screen and (min-width:50em) {
  markdown table tr > * {
    padding:0.25em 2em 0.25em;
  }
}

@media only screen and (min-width:80em) {
  .container {
    width:80em;
    margin:0 auto;
  }
}




